import React, {memo} from 'react';
import PropTypes from 'prop-types';
import SortBy from '../SortBy';
import { ToolSet, ToolSetInner } from '../../../../components/ToolSet';
import {
  generateSearchPath,
  getDefaultParams
} from '../../../../utils/urlHelpers/boats';
import {
  generateBrandedOemSearchPath,
  getDefaultParams as getDefaultOemParams
} from '../../../../utils/urlHelpers/oem';
import * as utils from '../../../../utils/scrollTo';
import './styles.css';
import isEqual from 'lodash/isEqual';

const SortSelector = (props) => {
  const handleSortChange = (data) => {
    let urlParams = props?.match?.params || {};
    let params = props.isBranded
      ? getDefaultOemParams(urlParams)
      : getDefaultParams(urlParams);
    const urlGeneratorFunction = props.isBranded
      ? generateBrandedOemSearchPath
      : generateSearchPath;
    props.history.push(urlGeneratorFunction({ sort: data }, params, true));
    if (props.scrollTo) {
      utils.scrollTo(props.scrollTo, { behavior: 'smooth' });
    }
  };

  return (
    <div id="sort" className="search-tools sort custom-select">
      <ToolSet>
        <ToolSetInner>
          <SortBy
            initialState="closed"
            match={props.match}
            params={props.params}
            closeOnOuterClick={true}
            closeOnInnerClick={true}
            onDataChange={ handleSortChange }
          />
        </ToolSetInner>
      </ToolSet>
    </div>
  );
};

SortSelector.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  params: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  scrollTo: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.string]),
  isBranded: PropTypes.bool
};

export default memo(SortSelector, isEqual);
