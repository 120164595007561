import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import { formatNumber } from '@dmm/lib-common/lib/formatting';
import Link from '../SEO/Link';
import { getFormattedParams as getFormattedParamsForSrp } from '../../utils/urlHelpers/boats';
import { getFormattedParamsArray as getFormattedParamsForEsrp } from '../../utils/urlHelpers/engines';
import { getFormattedParams as getFormattedParamsForOemSrp } from '../../utils/urlHelpers/oem/getFormattedParams';

import { PortalConfigContext } from '../../config/portal';
import './styles.css';

import { getBoatsConstants } from '../../constants/boats';
// import { getCurrentRoutes as rts } from '../../utils/language';
import {fixMinMaxOrder} from '../../pages/SearchResults/components/Filter/utils';
import {getRouteConstantsFromI18n} from '../../tppServices/translations/constants';
import { getMessages } from '../../tppServices/translations/messages';

const PillBox = (props) => {
  const context = useContext(PortalConfigContext);
  const getClearUrl = () => {
    const routeConstants = getRouteConstantsFromI18n();
    const { searchType } = props;
    switch (searchType) {
      case getBoatsConstants().SEARCH_TYPES.boats:
        return routeConstants.SEARCH_URL_ROOT;
      case getBoatsConstants().SEARCH_TYPES.brandedOem: {
        const urlRoot = routeConstants.BRANDED_OEM_SEARCH_URL_ROOT;
        const brandName = props.active.brand;
        return `${urlRoot}${brandName}/`;
      }
      case getBoatsConstants().SEARCH_TYPES.engines:
        return routeConstants.ENGINES_SEARCH_URL_ROOT;
    }
  };

  const getFormattedParams = () => {
    const { searchType } = props;
    switch (searchType) {
      case getBoatsConstants().SEARCH_TYPES.boats:
        return getSrpFormattedParams();
      case getBoatsConstants().SEARCH_TYPES.brandedOem:
        return getOemSrpFormattedParams();
      case getBoatsConstants().SEARCH_TYPES.engines:
        return getEsrpFormattedParams();
    }
  };

  const getSrpFormattedParams = () => {
    const { active, makeModel, seoMakeInfo, customUom } = props;
    return getFormattedParamsForSrp(active, makeModel, seoMakeInfo, customUom);
  };

  const getEsrpFormattedParams = () => {
    const { active, makeModel } = props;
    return getFormattedParamsForEsrp(active, makeModel);
  };

  const getOemSrpFormattedParams = () => {
    const { active, makeModel, customUom } = props;
    return getFormattedParamsForOemSrp(active, makeModel, customUom);
  };

  const getLinkProps = (link, callback) => {
    if (props.useMemoryAnchor) {
      return {
        to: link,
        onClick: callback
      };
    }

    return {
      href: link,
      onClick: (e) => { e.preventDefault(); callback(); }
    };
  };

    const locale = context.locale;
    const {
      handleDataChange,
    } = props;
    const { formatMessage: t } = useIntl();
    const messages = getMessages();
    const paramsAsArray = getFormattedParams().filter(
      (param) => param.key !== 'page'
    );
    return paramsAsArray.length === 0 ? null : (
      <div className="pill-box">
        <div className="scroller">
          <Link
            {...getLinkProps(getClearUrl(), props.onClear)}
            className="pill-box-clear"
          >
            {t(messages.clearFilter)}
          </Link>
          {paramsAsArray.map(
            ({
              indexKey,
              key,
              value,
              href,
              prefix,
              suffix,
              formattedKey,
              format,
              newValue,
              isFormatted
            }) => {
              return (
                <Link
                  key={indexKey || key}
                  className={classnames('pill-box-item', {
                    capitalize: !isFormatted
                  })}
                  {...getLinkProps(href, () => handleDataChange(key, newValue))}
                >
                  {`${formattedKey}: `}
                  {prefix}
                  {isObject(value)
                    ? fixMinMaxOrder(value)
                        .filter(
                          ([subKey]) =>
                            subKey !== 'prefix' && subKey !== 'suffix'
                        )
                        .map(([subKey, subValue], index) => (
                          <span key={`${key}.${subKey}`}>
                            {!!index && ' - '}
                            {format === 'number'
                              ? formatNumber(subValue, locale)
                              : subValue}
                          </span>
                        ))
                    : value}
                  {suffix}
                  <svg
                    className="pill-box-delete"
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                  >
                    <g>
                      <line
                        x1="18.495"
                        y2="18.495"
                        strokeMiterlimit="10"
                        strokeWidth="3"
                      />
                      <line
                        x1="18.495"
                        y1="18.495"
                        strokeMiterlimit="10"
                        strokeWidth="3"
                      />
                    </g>
                  </svg>
                </Link>
              );
            }
          )}
        </div>
      </div>
    );
};

PillBox.propTypes = {
  /** Active filters */
  active: PropTypes.shape({
    /** Selected condition */
    condition: PropTypes.oneOf(['new', 'used']),
    /** Selected engine type */
    engine: PropTypes.string,
    /** Selected seller type */
    forSale: PropTypes.oneOf(['dealer', 'owner']),
    /** Selected fuel types */
    fuelType: PropTypes.arrayOf(PropTypes.string),
    /** Selected hull materials */
    hullMaterial: PropTypes.arrayOf(PropTypes.string),
    /** Selected length range */
    length: PropTypes.shape({
      max: PropTypes.string,
      min: PropTypes.string
    }),
    /** Selected makes and models */
    makeModel: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    /** Selected types and classes */
    multiFacetedBoatTypeClass: PropTypes.shape({
      power: PropTypes.arrayOf(PropTypes.string),
      sail: PropTypes.arrayOf(PropTypes.string),
      unpowered: PropTypes.arrayOf(PropTypes.string)
    }),
    /** Selected number of engines */
    numberOfEngines: PropTypes.string,
    /** Selected radius: exact | number */
    radius: PropTypes.string,
    /** Selected sort: distance:asc, length:desc, etc. */
    sort: PropTypes.string,
    /** Selected subdivision initials */
    subdivision: PropTypes.string,
    /** Selected year range */
    year: PropTypes.shape({
      max: PropTypes.string,
      min: PropTypes.string
    }),
    /** Selected brand, only visible from branded oem srp */
    brand: PropTypes.string
  }),
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  }),
  handleDataChange: PropTypes.func,
  /** Available makes */
  makeModel: PropTypes.arrayOf(
    PropTypes.shape({
      /** Make formatted name */
      value: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      model: PropTypes.arrayOf(
        PropTypes.shape({
          /** Model formatted name */
          value: PropTypes.string.isRequired,
          count: PropTypes.number.isRequired
        })
      )
    })
  ),
  onClear: PropTypes.func,
  searchType: PropTypes.string.isRequired,
  seoMakeInfo: PropTypes.shape({
    make: PropTypes.string.isRequired,
    seoMakeName: PropTypes.string.isRequired
  }),
  // TODO: fix unit tests to allow here to use the proper type when we move to functional
  useMemoryAnchor: PropTypes.any
};

export default memo(PillBox, isEqual);
