import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { normalizeString } from '@dmm/lib-common/lib/formatting';
import { hyphenateUrlComponents, generateSearchPath } from '../../../../../../utils/urlHelpers/boats';
import {  translateMake } from '../../../../../../utils/commonHelper';
import { ToolSetOptionsItem, ToolSetOptions } from '../../../../../../components/ToolSet';
import { CollapsibleContent } from '../../../../../../components/Collapsible';
import Link from '../../../../../../components/SEO/Link';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { getFilteredFacet, emptySubFacet } from '../../../../../../utils/multiFacetHelper';
import { FilterTypeAhead } from '../..';
import { yieldToMain } from '../../../../../../utils';
import { useIntl } from 'react-intl';

import { setSearchTrackingClean, setGenericEventClean } from '../../../../../../store/actions/dataLayer';

import {useTPPServices} from '../../../../../../tppServices/tppDIHooks';
import { getMessages } from '../../../../../../tppServices/translations/messages';

const FilterMake = ({
  makes = [],
  loading,
  makeModels = {},
  maxMakeCount/* = bts().MAX_MAKE_COUNT*/,
  position,
  params,
  handleDataChange,
}) => {
  const dispatch = useDispatch();
  const {boatsConstants} = useTPPServices();
  maxMakeCount = maxMakeCount ?? boatsConstants.MAX_MAKE_COUNT;
  let showAllMakes = params.modal && params.modal.includes('make');
  const [selectedMakeModels, setSelectedMakeModels] = useState(makeModels);
  const allMakesWithBoats = getFilteredFacet(makes, selectedMakeModels);
  const messages = getMessages();
  const { formatMessage: t } = useIntl();

  useEffect(() => {
    handlePropsUpdate();
  }, [makeModels]);

  const handleToggleMake = async (make) => {
    let models = get(selectedMakeModels, make);
    let newMakeModels;
    if (!models) {
      setSearchTrackingClean({ makeModel: emptySubFacet(make, []) });
      newMakeModels = emptySubFacet(make, selectedMakeModels);
    } else {
      setGenericEventClean(`make removed - ${make}`);
      newMakeModels = omit(selectedMakeModels, make);
    }
    setSelectedMakeModels(newMakeModels);
    await yieldToMain();
    dispatch({ type: 'GET_DATA_LOADING' });
    await yieldToMain();
    handleDataChange('makeModel', newMakeModels);
  };

  const handlePropsUpdate = () => {
    setSelectedMakeModels(makeModels);
  };

  const renderMakes = (items, selectionCallback) => {
    return !!items.length && <ToolSetOptions>
      { items
        .map((make, i) => {
          const slugMake = normalizeString(hyphenateUrlComponents(make.value));
          const selectedModels = get(selectedMakeModels, slugMake);
          const makeSelected = !!selectedModels;
          return <ToolSetOptionsItem
            type="checkbox"
            key={`Make-${slugMake}-all-${i}`}
            id={`Make-${slugMake}-all`}
            name={`${t(messages.make)}-${slugMake}-${position}`}
            value={slugMake}
            selected={makeSelected}
            onClick={(value) => {
              handleToggleMake(value);
              selectionCallback(value);
            }}>
            <Link className="make-all-link" href={generateSearchPath({ makeModel: emptySubFacet(slugMake, selectedMakeModels) }, params, true)} onClick={(e) => e.preventDefault()}>{translateMake(make.value)}</Link>
          </ToolSetOptionsItem>;
        }
        )}
    </ToolSetOptions>;
  };

  return <div className={classnames('search-filter make-model', { loading })}>
    <CollapsibleContent initialState="open">
      <FilterTypeAhead
        items={allMakesWithBoats}
        selectedItems={Object.keys(selectedMakeModels)}
        id={`make-type-ahead-${position}`}
        moreMessage="moreMakes"
        placeholder={`${t(messages.makePlaceholder)}`}
        max={maxMakeCount}
        render={ renderMakes }
        showAll={showAllMakes}
        url={generateSearchPath(params) + 'modal-make/'}/>
    </CollapsibleContent>
  </div>;
};

FilterMake.propTypes = {
  handleDataChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  makeModels: PropTypes.object,
  makes: PropTypes.arrayOf(PropTypes.object),
  maxMakeCount: PropTypes.number,
  params: PropTypes.shape({
    modal: PropTypes.string,
  }).isRequired,
  position: PropTypes.string,
};

export default memo(FilterMake, isEqual);
