import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SRP_LITERALS, useCurrency, useMinMaxLabels, useMinMaxTypers, defaultGetMinMax, useDelayedTyping
} from '../../RefineSearch/FilterHelpers/useSRPFilters';
import {yieldToMain} from '../../../../../utils';
import {filtersLoading} from '../utils';
import {MinMaxInput} from '../FilterSections/MinMaxInput';
import PropTypes from 'prop-types';
import {SimpleDropdownList} from '@dmm/lib-react-ui-components';
import classnames from 'classnames';
import './styles.css';
import { BREAKPOINTS } from '../../../../../utils/commonHelper';
import { getMessages } from '../../../../../tppServices/translations/messages';

const {MIN, MAX, CURRENCIES} = SRP_LITERALS;

const CurrencyDropDown = ({show, handleCurrencyChange, currencyKey, selectedCurrency}) => {
  const dispatch = useDispatch();
  if (!show) {
    return null;
  }
  const onChangeCurrency = async(value) => {
    dispatch({type: 'GET_DATA_LOADING'});
    await yieldToMain();
    handleCurrencyChange('currency', value);
  };
  return (<div className="price-dropdown">
    <SimpleDropdownList
      key={currencyKey}
      selected={selectedCurrency}
      onChange={(e, value) =>
        onChangeCurrency(value)
      }
      items={CURRENCIES}
    />
  </div>);
};

CurrencyDropDown.propTypes = {
  handleCurrencyChange: PropTypes.func,
  currencyKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  selectedCurrency: PropTypes.string,
  show: PropTypes.bool
};

const CurrencySymbol = ({display, currencySymbol}) => {
  if (!display) {
    return null;
  }
  return <span className="symbol">{currencySymbol}</span>;
};

CurrencySymbol.propTypes = {
  currencySymbol: PropTypes.string,
  display: PropTypes.bool
};

const FilterPrice = ({handleDataChange, isThreeColumnLayout, userCurrency, min = '', max = '', position}) => {
  const messages = getMessages();

  const isWorking =  useSelector( state => state?.app?.isWorking || false );
  const componentWorking = useSelector( state => state?.app?.componentWorking || []);
  const isLoading = filtersLoading(isWorking, componentWorking);

  const filterNameKey = 'price';
  const {placeholderMin, placeholderMax, titleMin, titleMax, messageTo} = useMinMaxLabels(isThreeColumnLayout, messages, filterNameKey);
  const {
    isUsersCurrencyEnabled,
    smallMinClass,
    smallMaxClass,
    currencyKey,
    selectedCurrency,
    currencySymbol
  } = useCurrency(userCurrency, min, max);

  const {onType, onTypeEnd, disabledMinMax, minRef, maxRef} = useMinMaxTypers(handleDataChange, filterNameKey, defaultGetMinMax, min, max);
  const {onStopTyping} = useDelayedTyping(onType, onTypeEnd);

  const isDesktop = position === BREAKPOINTS.desktop;

  return (
    <div className="search-filter price">
      <div className="search-filter-group">
        <div className={smallMinClass}>
          <CurrencySymbol
            display={!!(smallMinClass && !isUsersCurrencyEnabled)}
            currencySymbol={currencySymbol} />
          <MinMaxInput
            filterName={filterNameKey}
            prefix={MIN}
            placeholder={placeholderMin}
            value={min}
            title={titleMin}
            cssClass={classnames({ 'price-min': !isUsersCurrencyEnabled })}
            onChange={onStopTyping}
            ref={minRef}
            disabled={ isDesktop && (disabledMinMax === MAX || isLoading) }
          />
        </div>
        <span>{messageTo}</span>
        <div className={smallMaxClass}>
          <CurrencySymbol
            display={!!(smallMaxClass && !isUsersCurrencyEnabled)}
            currencySymbol={currencySymbol} />
          <MinMaxInput
            filterName={filterNameKey}
            prefix={MAX}
            placeholder={placeholderMax}
            value={max}
            title={titleMax}
            cssClass={classnames({ 'price-max': !isUsersCurrencyEnabled })}
            onChange={onStopTyping}
            ref={maxRef}
            disabled={ isDesktop && (disabledMinMax === MIN || isLoading) }
          />
        </div>
        <CurrencyDropDown
          show={isUsersCurrencyEnabled}
          currencyKey={currencyKey}
          selectedCurrency={selectedCurrency}
          handleCurrencyChange={handleDataChange}/>
      </div>
    </div>
  );
};

FilterPrice.propTypes = {
  isThreeColumnLayout: PropTypes.bool,
  max: PropTypes.string,
  min: PropTypes.string,
  cookies: PropTypes.object,
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  }),
  handleDataChange: PropTypes.func,
  userCurrency: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  position: PropTypes.string,
};

export default memo(FilterPrice);
