import React, {useEffect, useState, memo} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import {ToolSetOptions, ToolSetOptionsItem} from '../../../../../components/ToolSet';
import Link from '../../../../../components/SEO/Link';
import {toHTMLId} from '../../../../../utils/commonHelper';
import isEqual from 'lodash/isEqual';
import * as urlHelpers from '../../../../../utils/urlHelpers/boats';
import {setGenericEventClean, setSearchTrackingClean} from '../../../../../store/actions/dataLayer';
import {yieldToMain} from '../../../../../utils';
import {unslugify} from '../../../../../utils/urlHelpers/shared';
import { getMessages } from '../../../../../tppServices/translations/messages';
import { useTPPServices } from '../../../../../tppServices/tppDIHooks';

const HullFilterItem = ({ facet, messages, selectedHullTypes, handleToggleHullType, updateHullMaterialList, params }) => {
  const intl = useIntl();
  const t = intl.formatMessage;
  const hullName = t(messages.hullMaterialFacetText[facet.value]);
  let unslugifiedFacet = unslugify(facet.urlId, '+', '-');
  unslugifiedFacet = unslugify(unslugifiedFacet, '--', '-');
  const nameId = toHTMLId(unslugifiedFacet);
  const { boatsConstants } = useTPPServices();
  const materialValue = boatsConstants.HULL_MATERIAL_MAPPING.value[facet.value] || facet.value;
  const materialUrlId = boatsConstants.HULL_MATERIAL_MAPPING.urlId[unslugifiedFacet] || unslugifiedFacet;

  return (
    <ToolSetOptionsItem
      type="checkbox"
      key={`hull-${nameId}`}
      id={`hull-${nameId}`}
      name={`hull-${nameId}`}
      value={materialValue}
      selected={includes(selectedHullTypes, materialUrlId)}
      onClick={() => handleToggleHullType(materialValue)}
    >
      <Link
        className={`class-${nameId}-link`}
        href={urlHelpers.generateSearchPath(
          { hullMaterial: updateHullMaterialList(materialValue) },
          params,
          true,
        )}
        onClick={(e) => e.preventDefault()}
      >
        {hullName}
      </Link>
    </ToolSetOptionsItem>
  );
};

HullFilterItem.propTypes = {
  facet: PropTypes.shape({
    count: PropTypes.number,
    urlId: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  messages: PropTypes.object.isRequired,
  selectedHullTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleToggleHullType: PropTypes.func.isRequired,
  updateHullMaterialList: PropTypes.func.isRequired,
  params: PropTypes.shape({
    hullMaterial: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string
    ])
  })
};

const FilterHullMaterial = ({
  handleDataChange,
  hullTypes,
  params,
  selectedHullTypes,
  position
}) => {
  const [hullTypesFilter, setHullTypesFilter] = useState(selectedHullTypes || []);
  const intl = useIntl();
  const messages = getMessages();
  const t = intl.formatMessage;
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedHullTypes !== undefined) {
      setHullTypesFilter(selectedHullTypes);
    }
  }, [selectedHullTypes]);

  const updateHullMaterialList = (hullType) => {
    if (includes(hullTypesFilter, hullType)) {
      return hullTypesFilter.filter(material => material !== hullType);
    }
    return [...hullTypesFilter, hullType];
  };

  const handleToggleHullType = async (hullType) => {
    const newHullTypes = updateHullMaterialList(hullType);
    const selectedHullTypesSizeBeforeUpdate = hullTypesFilter.length;
    if (selectedHullTypesSizeBeforeUpdate > newHullTypes.length) {
      setGenericEventClean(`hull material removed - ${hullType}`);
    } else {
      setSearchTrackingClean({ hullMaterial: [hullType] });
    }

    setHullTypesFilter(newHullTypes);
    await yieldToMain();
    if (position !== 'mobile') {
      dispatch({ type: 'GET_DATA_LOADING' });
      await yieldToMain();
    }

    handleDataChange('hullMaterial', newHullTypes);
  };

  const hullFilters = hullTypes
    .filter(facet => facet.count)
    .sort((facetA, facetB) => {
      const hullNameA = t(messages.hullMaterialFacetText[facetA.value]);
      const hullNameB = t(messages.hullMaterialFacetText[facetB.value]);
      return hullNameA > hullNameB ? 1 : -1;
    })
    .map(facet => (
      <HullFilterItem
        key={facet.value}
        facet={facet}
        messages={messages}
        t={t}
        selectedHullTypes={hullTypesFilter}
        handleToggleHullType={handleToggleHullType}
        updateHullMaterialList={updateHullMaterialList}
        params={params}
      />
    ));

  return (
    <div className="search-filter hull-material">
      {hullTypes.length ? (
        <ToolSetOptions>
          {hullFilters}
        </ToolSetOptions>
      ) : null}
    </div>
  );
};

FilterHullMaterial.propTypes = {
  handleDataChange: PropTypes.func.isRequired,
  hullTypes: PropTypes.arrayOf(PropTypes.shape({
    count: PropTypes.number,
    urlId: PropTypes.string,
    value: PropTypes.string
  })),
  params: PropTypes.shape({
    hullMaterial: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string
    ])
  }),
  selectedHullTypes: PropTypes.arrayOf(PropTypes.string),
  position: PropTypes.string
};

export default memo(FilterHullMaterial, isEqual);
