import React, { useState, useEffect, useContext, memo } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { EngineDetailsFilter } from '@dmm/lib-react-ui-components';
import { generateSearchPath } from '../../../../../utils/urlHelpers/boats';

import { v4 as uuid } from 'uuid';
import { DEBOUNCE_TIMEOUT_LONG } from '../../../../../constants';
import { PortalConfigContext } from '../../../../../config/portal';
import {getBoatConstantsFromI18n} from '../../../../../tppServices/translations/constants';
import { yieldToMain } from '../../../../../utils';
import {useDispatch} from 'react-redux';
import { getMessages } from '../../../../../tppServices/translations/messages';

const FilterEngineDetails = ({ params, data, facets, handleDataChange, tracking, position = '', ...props }) => {
  const context = useContext(PortalConfigContext);
  const isEngineFilterEnabled = get(context, 'supports.isEngineFilterEnabled', false);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    key: 0,
    open: false,
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      key: uuid(),
    }));
  }, [data]);

  const engine = get(data, 'engine', []);
  const numberOfEngines = get(data, 'numberOfEngines', 0);
  const engineOneHours = get(data, 'engineOneHours', {});

  const enginesDriveType = get(facets, 'enginesDriveType', []);
  const engineNumber = get(facets, 'numberOfEngines', []);
  const messages = getMessages();
  const { formatMessage: t } = useIntl();

  const handleDataNumberChange = async (e, value) => {
    e.preventDefault();
    tracking && tracking.facetAdded({ ['numberOfEngines']: value });
    setState((prev) => ({ ...prev, key: uuid() }));
    await yieldToMain();
    if (position !== 'mobile'){
      dispatch({ type: 'GET_DATA_LOADING' });
      await yieldToMain();
    }
    handleDataChange('numberOfEngines', value);
  };

  const handleEngineTypeDataChange = (value) => {
    handleFilterChange('engine', value);
  };

  const handleFilterChange = async (key, value) => {
    setState((prev) => ({ ...prev, key: uuid() }));
    tracking && tracking.facetAdded({ [key]: value });
    await yieldToMain();
    if (position !== 'mobile'){
      dispatch({ type: 'GET_DATA_LOADING' });
      await yieldToMain();
    }
    handleDataChange(key, value);
  };

  const handleEngineHoursChange = debounce(
    async (value) => {
      setState((prev) => ({ ...prev, key: uuid() }));
      tracking && tracking.facetAdded({ ['engineHours']: value });
      await yieldToMain();
      if (position !== 'mobile'){
        dispatch({ type: 'GET_DATA_LOADING' });
        await yieldToMain();
      }
      handleDataChange('engineOneHours', value);
    },
    DEBOUNCE_TIMEOUT_LONG
  );

  const handleSectionOpen = (isOpen) => {
    setState((prev) => ({
      ...prev,
      open: isOpen,
    }));
    props.onOpen && props.onOpen(isOpen);
  };

  const populateEnginesNumberProps = () => {
    const boatConstants = getBoatConstantsFromI18n();
    const maxEnginesNumberSearch = boatConstants.SRP_MAXIMUM_ENGINES_NUMBER_SEARCH;
    const engineNumberProps = {};
    const engineNumbers = engineNumber.filter((i) => i.value < 5);
    engineNumbers.forEach((item) => {
      engineNumberProps[item.value] = { ...item };
    });

    const toolsetRadioItems = [];
    for (let index = 0; index <= maxEnginesNumberSearch; index++) {
      const dashPosition = position ? '-' + position : '';
      if (index > 0 && !engineNumberProps[index]) {
        continue;
      }
      const indexValue = index === 0 ? t(messages.all) : (index === maxEnginesNumberSearch ? `${index}+` : index);
      toolsetRadioItems.push({
        defaultChecked: String(numberOfEngines) === String(index),
        id: `engine-number-${index}${dashPosition}`,
        name: `engine-number-${dashPosition}`,
        onClick: handleDataNumberChange,
        value: `${index}`,
        url: generateSearchPath({numberOfEngines: index}, params, true),
        text: indexValue,
        nofollow: true,
      });
    }
    return toolsetRadioItems;
  };

  const populateEngineTypeProps = () => ({
    id: 'engine-type',
    label: t(messages.engineDetailsFacet.engineType),
    header: t(messages.engineDetailsFacet.engineType),
    selectedValues: engine,
    onDataChange: handleEngineTypeDataChange,
    placeholder: t(messages.engineDetailsFacet.engineType),
    items: [...enginesDriveType]
      .sort((first, second) => t(messages.engineFacetText[first.value]) > t(messages.engineFacetText[second.value]) ? 1 : -1)
      .map((engineType, index) => ({
        id: `engine-type-${index}`,
        text: `${t(messages.engineFacetText[engineType.value])} (${engineType.count})`,
        url: generateSearchPath({ engine: [engineType.value] }, params, true),
        name: 'engine',
        value: engineType.value,
        filterValue: t(messages.engineFacetText[engineType.value]),
        onClick: (e) => { e.preventDefault(); },
        nofollow: true,
      }))
  });

  if (!isEngineFilterEnabled) {
    return null;
  }

  const filters =  [populateEngineTypeProps()];

  if (!engineNumber.length) {
    return null;
  }

  return (
    <EngineDetailsFilter
      isOpen={state.open}
      onOpen={ handleSectionOpen }
      key={`${state.key}`}
      header={ t(messages.engineDetailsFacet.title) }
      switcher={{
        header: t(messages.engineDetailsFacet.engineNumber),
        items: populateEnginesNumberProps(),
      }}
      numbersRange={{
        header: t(messages.engineDetailsFacet.engineHours),
        splitter: t(messages.to),
        onDataChange: handleEngineHoursChange,
        min: {
          value: engineOneHours.min,
          id: 'engine-hours-min',
          label: t(messages.quickSearch.min),
          placeholder: t(messages.quickSearch.min),
        },
        max: {
          value: engineOneHours.max,
          id: 'engine-hours-max',
          label: t(messages.quickSearch.max),
          placeholder: t(messages.quickSearch.max),
        }
      }}
      filters={filters}
    />
  );
};

FilterEngineDetails.propTypes = {
  params: PropTypes.object,
  data: PropTypes.object.isRequired,
  handleDataChange: PropTypes.func.isRequired,
  facets: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]).isRequired,
  position: PropTypes.string,
  tracking: PropTypes.shape({
    facetAdded: PropTypes.func.isRequired,
  }),
  onOpen: PropTypes.func,
};

export default memo(FilterEngineDetails, isEqual);
